'use strict';

window.GUI = function (GUI) {
    class SearchTableViewScreen extends GUI.TableViewScreenV2 {
        constructor() {
            super($('<div />'));
            this.searchInput = null;
            this._searchIndex = [];
        }

        titleBarConfig() {
            return {
                rightSide: TitleBarCfg.Button.SEARCH
            };
        }

        titleBarActionRight() {
            this._changeTitleBar();
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments) || true).then(function () {
                this._createSearchTitleBar();

                this.baseTitleBar = this.getTitleBar();
            }.bind(this));
        }

        viewWillAppear() {
            var baseCall = super.viewWillAppear(...arguments);
            this.antiGhostTimer = new AntiGhostTimer(this.reloadTable.bind(this, true), 250);

            this._toggleSearchBar(this.isSearchModeActive);

            this.searchInput.onTextChanged = function (value) {
                if (this._lastFilterValue !== value) {
                    this._filterTableContent(value);
                }

                this._lastFilterValue = value;
            }.bind(this);

            this.searchInput.onSubmit = function (value) {
                this._filterTableContent(value);
            }.bind(this);

            return baseCall;
        }

        reloadTable(reloadSearchResults, forceOriginalContent) {
            if (reloadSearchResults && this.isSearchModeActive && this.antiGhostTimer && !this.antiGhostTimer.isActive()) {
                this.tableContent = cloneObjectDeep(this.filteredTableContent);
            } else if (forceOriginalContent) {
                this.tableContent = cloneObjectDeep(this.originalTableContent);
            } else {
                this.originalTableContent = cloneObjectDeep(this.tableContent);
                this._searchIndex = [];
                this.originalTableContent.forEach(this._createSearchIndex.bind(this));

                if (this.isSearchModeActive) {
                    this._filterTableContent(this.lastSearchInput);
                }
            } // In this case we need to call the base because we are overwriting the arguments


            return super.reloadTable();
        }

        _createSearchIndex(section, sectionIdx) {
            this._searchIndex[sectionIdx] = new SearchUtils.Index("searchIndex" + sectionIdx);
            section.rows && section.rows.forEach(function (row, rowIdx) {
                var searchItem = this._getSearchIndexItem(sectionIdx, rowIdx);

                if (searchItem) {
                    this._searchIndex[sectionIdx].addItem(searchItem.uuid, searchItem.name, searchItem.description, searchItem.object);

                    this._getKeywordsForUuid(searchItem.uuid).forEach(function (keyword) {
                        this._searchIndex[sectionIdx].addKeyword(keyword, searchItem.uuid, 12);
                    }.bind(this));
                }
            }.bind(this));
        }

        reloadRowsSelective(section, start, end) {
            developerAttention("reloadRowsSelective not supported " + this.name);
            return super.reloadRowsSelective(...arguments);
        }

        _getSearchIndexItem(sectionIdx, rowIdx) {
            throw new Error("Not implemented");
        }

        _createCellFromSearchItem(item) {
            throw new Error("Not implemented");
        }

        _getKeywordsForUuid(uuid) {
            return [];
        }

        /**
         * Sorts the entries of the first section in a tableViewScreen
         * @param value
         * @private
         */
        _filterTableContent(value) {
            var filteredTableContent = [];

            if (nullEmptyString(value)) {
                var searchResults = SearchUtils.Index.prepareResult(this._searchIndex.map(function (searchIndex) {
                        return searchIndex.lookup(value);
                    }.bind(this)), 10, true),
                    originalClone = [];
                cloneObjectDeep(this.originalTableContent, originalClone);
                searchResults.forEach(function (searchIndex, sectionIndex) {
                    if (searchIndex.length > 0) {
                        var originalSection = originalClone[sectionIndex];
                        originalSection.rows = searchResults[sectionIndex].map(function (searchRes) {
                            return this._createCellFromSearchItem(searchRes.item);
                        }.bind(this));
                        filteredTableContent.push(originalSection);
                    }
                }.bind(this));
            } else {
                filteredTableContent = cloneObjectDeep(this.originalTableContent);
            }

            this.lastSearchInput = value;
            this.filteredTableContent = filteredTableContent;
            this.antiGhostTimer.fire();
        }

        _createSearchTitleBar() {
            var inputCfg = {
                    type: GUI.LxInputEnum.Type.SEARCH,
                    style: GUI.LxInputStyle.ROUNDED_SQUARE,
                    resetButton: true,
                    autoFocus: true,
                    isDark: true,
                    // will set isDark too.
                    leftIconSrc: Icon.SEARCH
                },
                searchInput = new GUI.LxInput(inputCfg, this),
                titlebarCfg = {
                    leftSide: TitleBarCfg.CUSTOM_LX_VIEW,
                    leftSideView: searchInput,
                    rightSide: TitleBarCfg.TEXT,
                    rightText: _('cancel')
                },
                searchTitleBar = new GUI.LxTitleBar(titlebarCfg);
            searchTitleBar.onRightButtonTapped = this._changeTitleBar.bind(this);
            this.searchInput = searchInput;
            this.searchTitleBar = searchTitleBar;
            this.prependSubview(searchTitleBar);
        }

        _changeTitleBar() {
            this._toggleSearchBar(!this.isSearchModeActive);

            if (this.isSearchModeActive) {
                this.antiGhostTimer.destroy();
                this.isSearchModeActive = false;
                this.reloadTable(null, true);
            } else {
                this.searchInput.setValue("");
                this.isSearchModeActive = true;
            }
        }

        _toggleSearchBar(show) {
            if (show) {
                this.hideSubview(this.baseTitleBar);
                this.showSubview(this.searchTitleBar);
            } else {
                this.hideSubview(this.searchTitleBar);
                this.showSubview(this.baseTitleBar);
            }
        }

    }

    GUI.SearchTableViewScreen = SearchTableViewScreen;
    return GUI;
}(window.GUI || {});
