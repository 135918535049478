'use strict';

window.GUI = function (GUI) {
    class TableViewScreen extends GUI.TableViewScreen {
        constructor() {
            super(...arguments);
        }

        // TableView DataSource Methods
        styleForTableView() {
            return TableViewStyle.COMFORT_MODE_2020;
        }

    }

    GUI.TableViewScreenV2 = TableViewScreen;
    return GUI;
}(window.GUI || {});
