'use strict';

window.GUI = function (GUI) {
    class StateViewBase extends GUI.View {
        constructor() {
            super(...arguments);
            Object.assign(this, StateHandler.Mixin);
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments);
        }

        viewWillAppear() {
            super.viewWillAppear(...arguments);

            this._registerForStates(this.getStateContainerUuid(), this.getStateIDs());
        }

        viewWillDisappear() {
            this._unregisterStates();

            return super.viewWillDisappear(...arguments);
        }

        /**
         * Returns the uuid of the state container to register with.
         * @return {string}
         */
        getStateContainerUuid() {
            throw new Error("The method getStateContainerUuid must be implemented in the subclass!");
        }

        /**
         * Returns an array containing the state ids this view is interested in. statesReceived will only be called
         * if one of the states specified in this array changes.
         * By default this method returns false, which means this view is interested in all states of the state cntr.
         * @return {Array|boolean}
         */
        getStateIDs() {
            return false;
        }

    }

    GUI.StateViewBase = StateViewBase;
    return GUI;
}(window.GUI || {});
