'use strict';

window.GUI = function (GUI) {
    class ControlStateView extends GUI.StateViewBase {
        constructor(control, element) {
            super(element);
            this.control = control;
        }

        /**
         * Returns the uuid of the state container to register with.
         * @return {string}
         */
        getStateContainerUuid() {
            return this.control.uuidAction;
        }

        /**
         * Called when the states did change (if stateIds have been provided - only if one of those states did change).
         * @param states
         */
        receivedStates(states) {
            this.states = states;
        }

        /**
         * Returns an array containing the state ids this view is interested in. statesReceived will only be called
         * if one of the states specified in this array changes.
         * By default this method returns false, which means this view is interested in all states of the state cntr.
         * @return {Array|boolean}
         */
        getStateIDs() {
            return super.getStateIDs(...arguments);
        }

    }

    GUI.ControlStateView = ControlStateView;
    return GUI;
}(window.GUI || {});
